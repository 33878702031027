body {
  width: 100vw;
  height: 100vh;
}
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.main {
  flex-grow: 1;
}
