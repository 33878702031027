.main-footer {
  color: white;
  background-color: #093250;
  padding-top: 1em;
  bottom: 0;
  width: 100%;
}
.social {
  color: white;
  margin-right: 20px;
}
.contacts {
  display: block;
  margin-bottom: 5px;
}
.contact {
  color: white;
}
