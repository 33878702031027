.form-container {
  padding: 5%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.login-form-box {
  width: 40vw;
  padding: 2%;
  border: 1px solid black;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}

.login-label {
  /* display: inline-block; */
  /* width: 20vw; */
}

.login-input {
  width: 30vw;
  height: 5vh;
  margin: 10px 0;
}

.login-button {
  display: block;
  margin: 5% auto;
  background-color: cadetblue;
}

.login-button:hover {
  color: white;
}

.input-wrap {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 50px;
}

.error {
  /* color: blue; */
  position: absolute;
  /* text-align: right; */
  right: 30px;
  bottom: 80%;
}
