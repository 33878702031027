.booking-container {
  padding: 5%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.booking-form-box {
  width: 50vw;
  padding: 5%;
  border: 1px solid black;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.input-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-button {
  background-color: cadetblue;
}

.add-button:hover {
  color: white;
}

.booking-input {
  align-items: right;
  width: 28vw;
  height: 5vh;
  margin: 10px 0;
}

.booking-textarea {
  /* display: block; */
  height: 10vh;
  width: 28vw;
}

.checkUser {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-button {
  display: flex;
  justify-content: center;
  justify-content: center;
  background-color: cadetblue;
}

.add-button {
  display: block;
  margin: 5% auto;
  background-color: cadetblue;
}

.add-button:hover {
  color: white;
}

.btn-adduser {
  display: block;
  width: 100px;
  margin: 5px auto;
}

.check-button {
  background-color: cadetblue;
  margin-left: 5px;
}

.checkUser-email {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
