.title {
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 10px;
}
.title.about,
.title.next {
  color: #093250;
}
.text {
  margin-bottom: 15px;
}
.item {
  width: 95vw;
  height: 70vh;
  align-items: center;
  text-align: center;
}

.image-slides {
  margin: 0 auto;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 10px;
  max-width: 45vw;
  object-fit: cover;
}

.section {
  padding: 5%;
  /* font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */
  font-size: larger;
}
.location-link {
  display: flex;
  align-items: center;
  color: #093250;
  border: 1px solid #093250;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 10px;
}
.location-link:hover,
.location-link:focus {
  color: #093250;
}

.subtitle {
  text-transform: uppercase;
}

.booking {
  background-color: #093250;
  color: white;
}
.email-icon {
  color: white;
  margin: 0 5px 0 10px;
}
.email {
  color: white;
}
